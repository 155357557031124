body {
  min-width: 100vw;
}

header {
  position: fixed;
}

@font-face {
  font-family: 'MyWebFont';
  src: url('../Fonts/Bahnschrift.woff') format('woff'),
       url('../Fonts/BAHNSCHRIFT.TTF') format('truetype');
}

.about-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  height: 100vh;
  width: auto;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.icind-about-logo {
  margin-top: -70px;
  margin-bottom: 10px;
}

.about-page p {
  max-width: 30%;
  font-size: 1.125rem;
  margin-top: 10px;
  line-height: 1.5;
  text-align: center;
  color: #dddddd;
  font-family: 'MyWebFont';
}

@media (max-width: 62.5rem){
  .about-page p {
    max-width: 90%;
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
    text-align: center;
    color: #dddddd;
  }
}

@media (max-width: 47.9375rem) {
  .about-page {
    width: 100%;
  }
  .about-page p {
    width: 70%;
  }
}
