.header {
  display: flex;
  width: 100%;
  background-color: transparent;
  padding: 5px 5px;
  z-index: 2;
}

.icind-header-logo {
  position: fixed;
  left: 5px;
  width: 36px;
  height: auto;
}

@media (max-width: 47.9375rem) {
  .icind-header-logo {
    display: none;
  }
}

.icind-header-logo:hover {
  animation: glitch 0.5s linear 0s 5;
}

@keyframes glitch {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(-5px);
    opacity: 0.8;
  }
  40% {
    transform: translateX(5px);
    opacity: 0.6;
  }
  60% {
    transform: translateX(-5px);
    opacity: 0.8;
  }
  80% {
    transform: translateX(5px);
    opacity: 0.6;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.header-title {
  display: flex;
  justify-content: right;
  flex: 1;
  font-size: 1.125rem;
}

.header-links {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
}

.header-links a {
  margin: 5px;
  color: #ffffff;
  padding: 5px 10px;
  text-decoration: none;
}

.header-links a:hover {
  color: #ffcc00;
  background-color: #333333;
  border-radius: 4px;
  animation: glitch 0.5s linear 0s 1;
}