p {
  font-size: 1.125rem;
}

h2 {
  font-size: 1.375rem;
}

@font-face {
  font-family: 'MyWebFont';
  src: url('../Fonts/Bahnschrift.woff') format('woff'),
       url('../Fonts/BAHNSCHRIFT.TTF') format('truetype');
} 

.home-background {
  position: fixed;
  cursor: default;
  opacity: 40%;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-section {
  position: relative;
  padding-top: 50px;
  z-index: 1;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
}

.wip-web {
  font-weight: 600;
  color: red;
  text-shadow:
  0.07em 0 blue,
  0 0.07em blue,
  -0.07em 0 blue,
  0 -0.07em blue;
  font-family: 'MyWebFont';
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: -50px;
}

.icind-logo {
  z-index: 2;
}

.hero-content {
  position: relative;
  z-index: 2;
}

@media (max-width: 56.25rem) {
  .hero-content {
    display: none;
  }
}

.ic-ind-desc {
  max-width: 50%;
  font-size: 1.5rem;
  position: relative;
  z-index: 2;
  text-shadow:
  0.07em 0 black,
  0 0.07em black,
  -0.07em 0 black,
  0 -0.07em black;
}

@media (max-width: 56.25rem) {
  .ic-ind-desc {
    max-width: 90%;
    font-size: 1.5rem;
    position: relative;
    z-index: 2;
    text-shadow:
    0.07em 0 black,
    0 0.07em black,
    -0.07em 0 black,
    0 -0.07em black;
  }
}

.hero-image {
  width: 100%;
  max-width: 9.375rem;
  height: auto;
  margin: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 2;
}

.hero-image2 {
  width: 100%;
  max-width: 15.625rem;
  height: auto;
  margin: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 2;
}

.hero-image:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.hero-image2:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.hero-section img {
  cursor: pointer;
}

.hero-section h4 {
  margin-top: 20px;
  color: #ffffff;
  text-align: center;
  z-index: 2;
}

.content-container {
  width: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.work-container {
  width: auto;
  min-width: 100%;
  padding-bottom: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  position: relative;
  z-index: 2;
}

.work-container .button-home {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 5px;
  min-height: 1.875rem;
  min-width: 6.25rem;
}

.work-container p, h2 {
  color: rgb(0, 0, 0);
  font-family: 'MyWebFont';
}

.work-container h2 {
  font-size: 1.625rem;
  font-weight: bold;
}

.work-container p {
  font-size: 1.375rem;
  font-weight: 500;
  font-family: 'MyWebFont';
}

.about-container {
  width: auto;
  min-width: 100%;
  padding: 20px;
  background-color: rgb(23, 9, 54);
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 2;
}

.about-image {
  max-width: 50%;
  height: 34.375rem;
  flex-shrink: 1;
  flex-grow: 1;
}

@media (max-width: 75rem) {
  .about-image {
    max-width: 50%;
    height: 18.75rem;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.about-text {
  flex: 1;
  color: #ffffff;
  text-align: left;
  font-family: 'MyWebFont';
}

.about-text h2 {
  font-family: 'MyWebFont';
  color: #ffffff;
}

.about-text p {
  color: #ffffff;
  min-width: 100%;
  font-size: 1rem;
  margin: 20px 0;
}

.button-home {
  text-decoration: none;
  color: #000000;
  border: 2px solid black;
  border-radius: 4px;
  padding: 5px 20px;
  cursor: pointer;
  margin: 10px 10px;
}

@keyframes grow-shrink {
  0% {
    transform: scale(1.0);
    opacity: 1;
  }
  11.11% {
    transform: scale(1.01);
    opacity: 1;
  }
  22.22% {
    transform: scale(1.02);
    opacity: 1;
  }
  33.33% {
    transform: scale(1.03);
    opacity: 1;
  }
  44.44% {
    transform: scale(1.04);
    opacity: 1;
  }
  55.55% {
    transform: scale(1.03);
    opacity: 1;
  }
  66.66% {
    transform: scale(1.02);
    opacity: 1;
  }
  77.77% {
    transform: scale(1.01);
    opacity: 1;
  }
  88.88% {
    transform: scale(1.0);
    opacity: 1;
  }
}

.button-home:hover {
  background-color: #f1f1f148;
  animation: glitch 1.5s linear infinite;
}

@keyframes glitch {
  0% {
    transform: scale(1.03);
    opacity: 1;
  }
  20% {
    transform: scale(1.07);
    opacity: 0.8;
  }
  40% {
    transform: scale(1.1);
    opacity: 0.6;
  }
  60% {
    transform: scale(1.07);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.04);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.0);
    opacity: 1;
  }
}

.about-text .button-home {
  background-color: transparent;
  font-family: 'MyWebFont';
  color: #fff;
  border: 2px solid blue;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.about-text .button-home:hover {
  background-color: #3b0b7a33;
}

@media (max-width: 1000px) {
  .hero-section {
    height: auto;
  }

  .content-container {
    width: auto;
    padding: 15px;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .about-image {
    width: 100%;
    max-width: none;
  }

  .about-text {
    margin-left: 0;
    text-align: center;
  }
}
